<template>
  <v-theme-provider dark>
    <base-section
      id="contact-us"
      class="primary"
      space="40"
    >
      <v-container>
        <v-row justify="center">
          <v-col
            cols="10"
            md="5"
            xl="10"
          >
            <base-title
              :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
              :title="translations.contactUsTitle"
            />

            <base-body space="0">
              <span class="white--text">
                {{ translations.contactUsDescription }}
              </span>
            </base-body>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <base-btn
              :block="$vuetify.breakpoint.smAndDown"
              color="white"
              large
              outlined
              @click="goToContact"
            >
              {{ translations.contactUsButton }}
            </base-btn>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  import { PL, EN } from '@/constants/lang.js'
  import { STORE_NAMESPACE_ALL } from '@/App.vue'
  import { mapState } from 'vuex'

  export default {
    name: 'SectionContactUs',
    methods: {
      goToContact () {
        const name = this.$route.meta.lang === PL ? 'Contact-pl' : 'Contact-en'
        this.$router.push({ name })
      },
    },
    computed: {
      ...mapState(STORE_NAMESPACE_ALL, [
        'translations',
      ]),
    },
  }
</script>
